

/////// ================ FOR BACKEND
export const ADMINISTRATOR_REGISTER_REQUEST = "[ADMINISTRATOR] REGISTER REQUEST";
export const ADMINISTRATORS_REGISTER_SUCCESS = "[ADMINISTRATOR] REGISTER SUCCESS";
export const ADMINISTRATORS_REGISTER_FAILURE = "[ADMINISTRATOR] REGISTER FAILURE";
export const ADMINISTRATOR_UPDATE_REQUEST = "[ADMINISTRATOR] UPDATE REQUEST";
export const ADMINISTRATORS_UPDATE_SUCCESS = "[ADMINISTRATOR] UPDATE SUCCESS";
export const ADMINISTRATORS_UPDATE_FAILURE = "[ADMINISTRATOR] UPDATE FAILURE";
export const ADMINISTRATOR_DELETE_REQUEST = "[ADMINISTRATOR] DELETE REQUEST";
export const ADMINISTRATORS_DELETE_SUCCESS = "[ADMINISTRATOR] DELETE SUCCESS";
export const ADMINISTRATORS_DELETE_FAILURE = "[ADMINISTRATOR] DELETE FAILURE";

export const ADMINISTRATOR_LOGIN_REQUEST = "[ADMINISTRATOR] LOGIN REQUEST";
export const ADMINISTRATOR_LOGIN_SUCCESS = "[ADMINISTRATOR] LOGIN SUCCESS";
export const ADMINISTRATOR_LOGIN_FAILURE = "[ADMINISTRATOR] LOGIN FAILURE";

export const LOGIN_WITH_TOKEN_REQUEST = "[ADMINISTRATOR] LOGIN WITH TOKEN REQUEST";
export const LOGIN_WITH_TOKEN_SUCCESS = "[ADMINISTRATOR] LOGIN WITH TOKEN SUCCESS";
export const LOGIN_WITH_TOKEN_FAILURE = "[ADMINISTRATOR] LOGIN WITH TOKEN FAILURE";

export const ADMINISTRATOR_LOGOUT = "[ADMINISTRATOR] LOGOUT";

export const GET_ADMINISTRATOR_LIST_REQUEST = "[ADMINISTRATOR] GET LIST REQUEST";
export const GET_ADMINISTRATOR_LIST_SUCCESS = "[ADMINISTRATOR] GET LIST SUCCESS";
export const GET_ADMINISTRATOR_LIST_FAILURE = "[ADMINISTRATOR] GET LIST FAILURE";

export const GET_USER_LIST_REQUEST = "[USER] GET LIST REQUEST";
export const GET_USER_LIST_SUCCESS = "[USER] GET LIST SUCCESS";
export const GET_USER_LIST_FAILURE = "[USER] GET LIST FAILURE";

export const USER_DELETE_REQUEST = "[USER] DELETE REQUEST";
export const USER_DELETE_SUCCESS = "[USER] DELETE SUCCESS";
export const USER_DELETE_FAILURE = "[USER] DELETE FAILURE";

export const UPDATE_USER_STATUS_REQUEST = "[USER] UPDATE STATUS REQUEST";
export const UPDATE_USER_STATUS_SUCCESS = "[USER] UPDATE STATUS SUCCESS";
export const UPDATE_USER_STATUS_FAILURE = "[USER] UPDATE STATUS FAILURE";

export const GET_COGNITOUSER_LIST_REQUEST = "[COGNITOUSER] GET LIST REQUEST";
export const GET_COGNITOUSER_LIST_SUCCESS = "[COGNITOUSER] GET LIST SUCCESS";
export const GET_COGNITOUSER_LIST_FAILURE = "[COGNITOUSER] GET LIST FAILURE";
export const DELETE_COGNITOUSER_REQUEST = "[COGNITOUSER] DELETE REQUEST";
export const DELETE_COGNITOUSER_SUCCESS = "[COGNITOUSER] DELETE SUCCESS";
export const DELETE_COGNITOUSER_FAILURE = "[COGNITOUSER] DELETE FAILURE";
export const UPDATE_COGNITOUSER_EMAILVERIFIED_REQUEST = "[COGNITOUSER] UPDATE EMAILVERIFIED STATUS REQUEST";
export const UPDATE_COGNITOUSER_EMAILVERIFIED_SUCCESS = "[COGNITOUSER] UPDATE EMAILVERIFIED STATUS SUCCESS";
export const UPDATE_COGNITOUSER_EMAILVERIFIED_FAILURE = "[COGNITOUSER] UPDATE EMAILVERIFIED STATUS FAILURE";
export const COGNITOUSER_UPDATEINFO_REQUEST = "[COGNITOUSER] UPDATEINFO REQUEST";
export const COGNITOUSER_UPDATEINFO_SUCCESS = "[COGNITOUSER] UPDATEINFO SUCCESS";
export const COGNITOUSER_UPDATEINFO_FAILURE = "[COGNITOUSER] UPDATEINFO FAILURE";

export const GET_CELLPHONE_CARRIER_LIST_REQUEST = "[CELLPHONECARRIER] GET LIST REQUEST";
export const GET_CELLPHONE_CARRIER_LIST_SUCCESS = "[CELLPHONECARRIER] GET LIST SUCCESS";
export const GET_CELLPHONE_CARRIER_LIST_FAILURE = "[CELLPHONECARRIER] GET LIST FAILURE";
export const ADD_CELLPHONE_CARRIER_REQUEST = "[CELLPHONECARRIER] ADD REQUEST";
export const ADD_CELLPHONE_CARRIER_SUCCESS = "[CELLPHONECARRIER] ADD SUCCESS";
export const ADD_CELLPHONE_CARRIER_FAILURE = "[CELLPHONECARRIER] ADD FAILURE";
export const DELETE_CELLPHONE_CARRIER_REQUEST = "[CELLPHONECARRIER] DELETE REQUEST";
export const DELETE_CELLPHONE_CARRIER_SUCCESS = "[CELLPHONECARRIER] DELETE SUCCESS";
export const DELETE_CELLPHONE_CARRIER_FAILURE = "[CELLPHONECARRIER] DELETE FAILURE";

export const GET_NOTIFY_LIST_REQUEST = "[NOTIFY] GET LIST REQUEST";
export const GET_NOTIFY_LIST_SUCCESS = "[NOTIFY] GET LIST SUCCESS";
export const GET_NOTIFY_LIST_FAILURE = "[NOTIFY] GET LIST FAILURE";
export const ADD_NOTIFY_REQUEST = "[NOTIFY] ADD REQUEST";
export const ADD_NOTIFY_SUCCESS = "[NOTIFY] ADD SUCCESS";
export const ADD_NOTIFY_FAILURE = "[NOTIFY] ADD FAILURE";
export const DELETE_NOTIFY_REQUEST = "[NOTIFY] DELETE REQUEST";
export const DELETE_NOTIFY_SUCCESS = "[NOTIFY] DELETE SUCCESS";
export const DELETE_NOTIFY_FAILURE = "[NOTIFY] DELETE FAILURE";
export const UPDATE_NOTIFY_REQUEST = "[NOTIFY] UPDATE REQUEST";
export const UPDATE_NOTIFY_SUCCESS = "[NOTIFY] UPDATE SUCCESS";
export const UPDATE_NOTIFY_FAILURE = "[NOTIFY] UPDATE FAILURE";

export const GET_COURT_LIST_REQUEST = "[COURT] GET LIST REQUEST";
export const GET_COURT_LIST_SUCCESS = "[COURT] GET LIST SUCCESS";
export const GET_COURT_LIST_FAILURE = "[COURT] GET LIST FAILURE";
export const ADD_COURT_REQUEST = "[COURT] ADD REQUEST";
export const ADD_COURT_SUCCESS = "[COURT] ADD SUCCESS";
export const ADD_COURT_FAILURE = "[COURT] ADD FAILURE";
export const UPDATE_COURT_ACTIVE_REQUEST = "[COURT] UPDATE ACTIVE REQUEST";
export const UPDATE_COURT_ACTIVE_SUCCESS = "[COURT] UPDATE ACTIVE SUCCESS";
export const UPDATE_COURT_ACTIVE_FAILURE = "[COURT] UPDATE ACTIVE FAILURE";
export const COURT_DELETE_REQUEST = "[COURT] DELETE REQUEST";
export const COURT_DELETE_SUCCESS = "[COURT] DELETE SUCCESS";
export const COURT_DELETE_FAILURE = "[COURT] DELETE FAILURE";

export const GET_LOG_LIST_REQUEST = "[LOG] GET LIST REQUEST";
export const GET_LOG_LIST_SUCCESS = "[LOG] GET LIST SUCCESS";
export const GET_LOG_LIST_FAILURE = "[LOG] GET LIST FAILURE";
export const LOG_DELETE_REQUEST = "[LOG] DELETE REQUEST";
export const LOG_DELETE_SUCCESS = "[LOG] DELETE SUCCESS";
export const LOG_DELETE_FAILURE = "[LOG] DELETE FAILURE";

export const GET_ORDER_LIST_REQUEST = "[ORDER] GET LIST REQUEST";
export const GET_ORDER_LIST_SUCCESS = "[ORDER] GET LIST SUCCESS";
export const GET_ORDER_LIST_FAILURE = "[ORDER] GET LIST FAILURE";

export const GET_ORDER_DOCUMENT_REQUEST = "[ORDER] GET ORDER DOCUMENT REQUEST";
export const GET_ORDER_DOCUMENT_SUCCESS = "[ORDER] GET ORDER DOCUMENT SUCCESS";
export const GET_ORDER_DOCUMENT_FAILURE = "[ORDER] GET ORDER DOCUMENT FAILURE";

export const DELETE_ORDER_DOCUMENT_REQUEST = "[ORDER] DELETE ORDER DOCUMENT REQUEST";
export const DELETE_ORDER_DOCUMENT_SUCCESS = "[ORDER] DELETE ORDER DOCUMENT SUCCESS";
export const DELETE_ORDER_DOCUMENT_FAILURE = "[ORDER] DELETE ORDER DOCUMENT FAILURE";

// Bars action types
export const CREATE_BAR_REQUEST = "[BAR] CREATE REQUEST";
export const CREATE_BAR_SUCCESS = "[BAR] CREATE SUCCESS";
export const CREATE_BAR_FAILURE = "[BAR] CREATE FAILURE";
export const GET_BAR_LIST_REQUEST = "[BAR] GET LIST REQUEST";
export const GET_BAR_LIST_SUCCESS = "[BAR] GET LIST SUCCESS";
export const GET_BAR_LIST_FAILURE = "[BAR] GET LIST FAILURE";
export const DELETE_BAR_REQUEST = "[BAR] DELETE REQUEST";
export const DELETE_BAR_SUCCESS = "[BAR] DELETE SUCCESS";
export const DELETE_BAR_FAILURE = "[BAR] DELETE FAILURE";