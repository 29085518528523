import * as Actions from "../constants";
import { DispatchAction } from "../../types/store";

const initialState = {
  isLoading: false,
  error: null,
  total: 0,
  limit: 0,
  list: []
};

const logReducer = ( state, action: DispatchAction ) => {

  switch (action.type) {
    case Actions.GET_ORDER_LIST_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case Actions.GET_ORDER_LIST_SUCCESS: {
      return {
        ...state,
        total: action.payload.data.length,
        list: action.payload.data,
        isLoading: false
      };
    }
    case Actions.GET_ORDER_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.error
      };
    }
    case Actions.GET_ORDER_DOCUMENT_REQUEST: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case Actions.GET_ORDER_DOCUMENT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case Actions.GET_ORDER_DOCUMENT_FAILURE: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case Actions.DELETE_ORDER_DOCUMENT_REQUEST: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case Actions.DELETE_ORDER_DOCUMENT_SUCCESS: {
      console.log("DeleteSuccess", action.payload.data);
      return {
        ...state,
        list:state.list.map((item:any) => item.ID === action.payload.data.ID ? action.payload.data : item),
        isLoading: false
      }
    }
    case Actions.DELETE_ORDER_DOCUMENT_FAILURE: {
      return {
        ...state,
        isLoading: false
      }
    }
    default: {
      if(!state)
        return initialState;
      return state;
    }
  }

};

export default logReducer;
