import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import StoreProvider from "./providers/StoreProvider";
import { ToastContainer } from "react-toastify";
import AuthorizingProvider from './providers/AuthorizingProvider';
import "./App.css";

function App() {
  const content = useRoutes(router);

  return (
    <StoreProvider>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <AuthorizingProvider>
            {content}
          </AuthorizingProvider>
        </LocalizationProvider>
      </ThemeProvider>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </StoreProvider>
  );
}
export default App;
