import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { loginWithToken } from "../redux/actions/login.action";
import { useNavigate } from "react-router-dom";

export default function AuthorizedComponent({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(({ loginReducer }) => loginReducer);
  React.useEffect(() => {
    const accessToken: string | null = localStorage.getItem("access-token");
    if (accessToken) {
      dispatch(loginWithToken());
    } else {
      navigate("/signin");
    }
  }, [dispatch]);

  return isLoading ? <></> : <React.Fragment>{children}</React.Fragment>;
}

AuthorizedComponent.displayName="authorizedComponent";