import * as Actions from "../constants";
import { DispatchAction } from "../../types/store";

const initialState = {
  isLoading: false,
  error: null,
  total: 0,
  limit: 0,
  list: []
};

const courtReducer = ( state, action: DispatchAction ) => {

  switch (action.type) {

    case Actions.ADD_COURT_REQUEST: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case Actions.ADD_COURT_SUCCESS: {
      let newOne = true;
      let newArray = state.list.map((row:any) => {
        if(row.ID === action.payload.data['ID']) {
          newOne = false;
          return {...action.payload.data}
        } else
          return row;
      });
      if(newOne) {
        newArray = [...newArray, action.payload.data];
      }

      return {
        ...state,
        isLoading: false,
        list: [...newArray]
      }
    }
    case Actions.ADD_COURT_FAILURE: {
      return {
        ...state,
        isLoading: false
      }
    }
    case Actions.GET_COURT_LIST_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case Actions.GET_COURT_LIST_SUCCESS: {
      return {
        ...state,
        total: action.payload.data.length,
        list: action.payload.data,
        isLoading: false
      };
    }
    case Actions.GET_COURT_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.error
      };
    }
    case Actions.UPDATE_COURT_ACTIVE_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case Actions.UPDATE_COURT_ACTIVE_SUCCESS: {
      return {
        ...state,
        list: state.list.map((row) => row.ID === action.payload.data['ID'] ? {...row, active: row.active ? false : true } : row),
        isLoading: false
      }
    }
    case Actions.UPDATE_COURT_ACTIVE_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case Actions.COURT_DELETE_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.COURT_DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false
      }
    }
    case Actions.COURT_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        list: state.list.filter((item) => item.ID !== action.payload.data.ID)
      }
    }
    default: {
      if(!state)
        return initialState;
      return state;
    }
  }

};

export default courtReducer;
