import * as Actions from "../constants";
import { DispatchAction } from "../../types/store";

const initialState = {
  isLoading: false,
  error: null,
  total: 0,
  limit: 0,
  list: []
};

const logReducer = ( state, action: DispatchAction ) => {

  switch (action.type) {
    case Actions.GET_LOG_LIST_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case Actions.GET_LOG_LIST_SUCCESS: {
      return {
        ...state,
        total: action.payload.data.length,
        list: action.payload.data,
        isLoading: false
      };
    }
    case Actions.GET_LOG_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        list: [],
        error: action.error
      };
    }
    case Actions.LOG_DELETE_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.LOG_DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false
      }
    }
    case Actions.LOG_DELETE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        list: state.list.filter((item) => item.ID !== action.payload.data.ID)
      }
    }
    default: {
      if(!state)
        return initialState;
      return state;
    }
  }

};

export default logReducer;
