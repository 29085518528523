import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import { RouteType } from "./types/shared";

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Auth from "./providers/AuthorizedProvider";
import { roleConfig } from './config/roleconfig';

const Loader = (Component) => (props) =>
  (
    
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const Overview = Loader(lazy(() => import('src/content/overview')));
const Signin = Loader(lazy(() => import('src/content/signin')));

// Status
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

// Managements / Users, Accounts
/*
const ManagementUsers = Loader(
  lazy(() => import('src/content/manageaccounts/users'))
);
*/
const ManagementAdmins = Loader(
  lazy(() => import('src/content/manageaccounts/admins'))
);
const ManagementCognitoUsers = Loader(
  lazy(() => import('src/content/manageaccounts/cognitousers'))
);

// Managements / Carriers
const ManagementCarriers = Loader(
  lazy(() => import('src/content/manageitems/cellphonecarrier'))
);
// Managements / Courts
const ManagementCourts = Loader(
  lazy(() => import('src/content/manageitems/court'))
);

// Managements / Notify
const ManagementNotify = Loader(
  lazy(() => import('src/content/managenotifications/notify'))
);
/*
// Managements / Notifications
const ManagementNotifications = Loader(
  lazy(() => import('src/content/managenotifications/notifications'))
);
*/
// managelogs
const ManagementLogs = Loader(
  lazy(() => import('src/content/managelogs'))
);
// manageorderdocuments
const ManagementOrderdocuments = Loader(
  lazy(() => import('src/content/manageorderdocuments'))
);

// Others
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="manageaccounts" replace />
      },
      {
        path: 'signin',
        element: <Signin />
      },
      {
        path: 'status',

        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="profile" replace />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="settings" replace />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },
  {
    path: 'manageaccounts',
    element: <Auth allowedRoles={[roleConfig.admin.administrator, roleConfig.admin.superadministrator]} />,
    children: [
      {
        path: '',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            //element: <Navigate to="users" replace />
            element: <Navigate to="cognitousers" replace />
          },
          /*
          {
            path: 'users',
            element: <ManagementUsers />
          },
          */
          {
            path: 'cognitousers',
            element: <ManagementCognitoUsers />
          },
          {
            path: 'admins',
            element: <Auth allowedRoles={[roleConfig.admin.superadministrator]} />,
            children: [
              {
                path: '',
                //element: <Navigate to="users" replace />
                element: <ManagementAdmins />
              },
            ]
          },
        ]
      }
    ]
  },
  {
    path: 'manageitems',
    element: <Auth allowedRoles={[roleConfig.admin.administrator, roleConfig.admin.superadministrator]} />,
    children: [
      {
        path: '',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <Navigate to="cellphonecarrier" replace />
          },
          {
            path: 'court',
            element: <ManagementCourts />
          },
          {
            path: 'cellphonecarrier',
            element: <ManagementCarriers />
          }
        ]
      }
    ]
  },
  {
    path: 'managenotifications',
    element: <Auth allowedRoles={[roleConfig.admin.administrator, roleConfig.admin.superadministrator]} />,
    children: [
      {
        path: '',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <Navigate to="notify" replace />
          },
          {
            path: 'notify',
            element: <ManagementNotify />
          },
          /*
          {
            path: 'notifications',
            element: <ManagementNotifications />
          }
            */
        ]
      }
    ]
  },
  {
    path: 'managelogs',
    element: <Auth allowedRoles={[roleConfig.admin.administrator, roleConfig.admin.superadministrator]} />,
    children: [
      {
        path: '',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <ManagementLogs />
          }
        ]
      }
    ]
  },
  {
    path: 'manageorderdocs',
    element: <Auth allowedRoles={[roleConfig.admin.administrator, roleConfig.admin.superadministrator]} />,
    children: [
      {
        path: '',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <ManagementOrderdocuments />
          }
        ]
      }
    ]
  }
];

export default routes;
