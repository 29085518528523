import { all } from "axios";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const Auth = ({ allowedRoles } : any) => {
 
  let { currentAdmin } = useSelector(({ loginReducer }) => loginReducer);

  if(!currentAdmin)
    currentAdmin = localStorage.getItem("currentadministrator");

  return allowedRoles.find((role : string) => currentAdmin && role === currentAdmin.role /*user.role.includes(role)*/) ? (
    <Outlet />
  ) : (
    <></>
  );
};

export default Auth;