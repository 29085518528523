import { combineReducers } from "redux";
import loginReducer from "./login.reducer";
import userReducer from "./user.reducer";
import cognitouserReducer from "./cognitouser.reducer";
import adminReducer from "./admin.reducer";
import courtReducer from "./court.reducer";
import cellphoneCarrierReducer from "./cellphonecarrier.reducer";
import notifyReducer from "./notify.reducer";
import logReducer from "./log.reducer";
import barsReducer from "./bars.reducer";
import orderReducer from "./order.reducer";

const reducers = combineReducers({
  barsReducer,
  loginReducer,
  userReducer,
  cognitouserReducer,
  adminReducer,
  courtReducer,
  cellphoneCarrierReducer,
  notifyReducer,
  logReducer,
  orderReducer
});

export default reducers;