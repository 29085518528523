import * as Actions from "../constants";
import { DispatchAction } from "../../types/store";

const initialState = {
  isLoading: false,
  error: null,
  total: 0,
  list: []
};

const barsReducer = (state, action: DispatchAction) => {
  switch (action.type) {
  case Actions.CREATE_BAR_REQUEST: {
    return {
      ...state,
      isLoading: true
    };
  }
  case Actions.CREATE_BAR_SUCCESS: {
    const { data } = action.payload;
    let mustAdd = true;
    let newList = state.list.map((item) => {
      if(item.ID === data.ID) {
        mustAdd = false;
        return data;
      } else {
        return item;
      }
    });
    if(mustAdd) {
      newList = [...newList, data];
    }

    return {
      ...state,
      isLoading: false,
      list: newList
    };
  }
  case Actions.CREATE_BAR_FAILURE: {
    return {
      ...state,
      isLoading: false,
      error: action.error
    };
  }

  case Actions.GET_BAR_LIST_REQUEST: {
    return {
      ...state,
      isLoading: true
    };
  }
  case Actions.GET_BAR_LIST_SUCCESS: {
    return {
      ...state,
      total: action.payload.data.length,
      list: [...action.payload.data],
      isLoading: false
    };
  }
  case Actions.GET_BAR_LIST_FAILURE: {
    return {
      ...state,
      isLoading: false,
      list: [],
      error: action.error
    };
  }
  case Actions.DELETE_BAR_REQUEST: {
    return {
      ...state, 
      isLoading: true
    }
  }
  case Actions.DELETE_BAR_SUCCESS: {
    return {
      ...state,
      isLoading: false,
      list: state.list.filter((row) => row.ID !== action.payload.data.ID)
    }
  }
  case Actions.DELETE_BAR_FAILURE: {
    return {
      ...state,
      isLoading: false
    }
  }

  default: {
    if(!state)
      return initialState;
    return state;
  }
  }
};

export default barsReducer;