import * as Actions from "../constants";
import { DispatchAction } from "../../types/store";

const initialState = {
  isLoading: false,
  error: null,
  total: 0,
  limit: 0,
  list: []
};

const notifyReducer = ( state, action: DispatchAction ) => {

  switch (action.type) {

    case Actions.ADD_NOTIFY_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case Actions.ADD_NOTIFY_SUCCESS: {
      let newOne = true;
      let newArray = state.list.map((row:any) => {
        if(row.ID === action.payload.data['ID']) {
          newOne = false;
          return {...action.payload.data}
        } else
          return row;
      });
      if(newOne) {
        newArray = [...newArray, action.payload.data];
      }

      return {
        ...state,
        isLoading: false,
        list: [...newArray]
      }
    }
    case Actions.ADD_NOTIFY_FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case Actions.GET_NOTIFY_LIST_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.GET_NOTIFY_LIST_SUCCESS: {
      return {
        ...state,
        total: action.payload.data.length,
        list: action.payload.data,
        isLoading: false
      }
    }
    case Actions.GET_NOTIFY_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false
      }
    }
    case Actions.DELETE_NOTIFY_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.DELETE_NOTIFY_FAILURE: {
      return {
        ...state,
        isLoading: false
      }
    }
    case Actions.DELETE_NOTIFY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        list: state.list.filter((item) => item.ID !== action.payload.data.ID)
      }
    }
    case Actions.UPDATE_NOTIFY_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case Actions.UPDATE_NOTIFY_FAILURE: {
      return {
        ...state,
        isLoading: false
      }
    }
    case Actions.UPDATE_NOTIFY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        list: state.list.map((item) => item.ID !== action.payload.data.ID ? item : action.payload.data)
      }
    }
    default: {
      if(!state)
        return initialState;
      return state;
    }
  }

};

export default notifyReducer;
